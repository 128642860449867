import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import ValidationIcon from './ValicationIcon'

export const IDLE = 1
export const STARTED = 2
export const VALIDATED = 3
export const FAILED = 4
export const WARNING = 5

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        margin: '10px 0'
    },
    icon: {
        flex: '0 1 30px'
    },
    text: {
        flex: '1',
        marginTop: '2px'
    },
    errorText: {
        '& p': {
            color: '#888'
        }
    },
    label: {
        display: 'flex',
        flexDirection: 'row',
        '& sup': {
            color: '#888',
            fontSize: '60%',
            marginLeft: '5px'
        }
    }
})

class ValidationStep extends Component {
    render() {
        const { status, text, errorComponent, classes, isBeta } = this.props
        return (
            <div className={classes.container}>
                <div className={classes.icon}>
                    <ValidationIcon status={status} />
                </div>
                <div className={classes.text}>
                    <div className={classes.label}>
                        <Typography>{text}</Typography>
                        {isBeta && <sup>BETA</sup>}
                    </div>
                    <div className={classes.errorText}>{errorComponent}</div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ValidationStep)
