import { Component } from 'react'
import { connect } from 'react-redux'
import {
    listenForRealtimeTracking,
    listenForRealtimeTrackingStop
} from '../core/validationActions'

class ListenForTracking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMounted: false,
            isListening: false
        }
    }

    componentDidMount() {
        this.setState({
            isMounted: true
        })
    }

    componentWillMount() {
        if (!this.state.isListening) {
            this.listenForChange()
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.listenForChange()
    }

    componentWillUnmount() {
        this.props.listenForRealtimeTrackingStop()
        this.setState({
            isMounted: false,
            isListening: false
        })
    }

    listenForChange() {
        if (this.state.isListening) {
            this.props.listenForRealtimeTrackingStop()
        }
        this.props.listenForRealtimeTracking()
        this.setState({
            isListening: true
        })
    }

    render() {
        return ''
    }
}

const mapDispatchToProps = Object.assign(
    {},
    {
        listenForRealtimeTracking: listenForRealtimeTracking,
        listenForRealtimeTrackingStop: listenForRealtimeTrackingStop
    }
)

export default connect(
    null,
    mapDispatchToProps
)(ListenForTracking)
