import baseVast from '../../../res/baseVast'
import baseJSON from '../../../res/baseSSPJson'
import config from '../../../config'

export function generateVastForTest(sessionId) {
    const trackingUrl = hydrateTracking(sessionId) + '&'
    const videoUrl = config.mediaFileVideo

    const vast = replaceUrls(trackingUrl, videoUrl)

    return vast
}

export function wrapVASTInJSON(vast) {
    // Force a copy of the JSON to prevent issue when calling this method twice
    const json = JSON.parse(JSON.stringify(baseJSON))
    json.ads[0].content = vast
    return json
}

function replaceUrls(serverUrl, mediaFileUrl) {
    return baseVast
        .replace(/\[SERVERURL\]/g, serverUrl)
        .replace(/\[MEDIAFILEURL\]/g, mediaFileUrl)
}

export function hydrateTracking(sessionId) {
    return `${config.apiBaseUrl}/session/tracking?testSessionId=${sessionId}`
}

export function hydrateSSPUrl(sessionId) {
    return `${config.apiBaseUrl}/session/ad-request?testSessionId=${sessionId}`
}
