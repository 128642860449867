export const GET_SESSION_SUCCESS = 'sdk-validation/getSession/success'
export const GET_SESSION_ERROR = 'sdk-validation/getSession/error'
export const TEST_SUBMIT_SUCCESS = 'sdk-validation/testSubmit/success'
export const TEST_SUBMIT_ERROR = 'sdk-validation/testSubmit/error'
export const TRACKING_RECEIVED = 'sdk-validation/trackingReceived/success'
export const ADREQUEST_RECEIVED = 'sdk-validation/adRequestReceived/success'
export const SDK_VERSION_RECEIVED = 'sdk-validation/getSDKVersion/success'
export const SDK_VERSION_ERROR = 'sdk-validation/getSDKVersion/error'
export const APPADS_TXT_RECEIVED = 'sdk-validation/getAppAdsTxtStatus/success'
export const APPADS_TXT_ERROR = 'sdk-validation/getAppAdsTxtStatus/error'
export const CREATIVE_CHECK_RECEIVED =
    'sdk-validation/creativeCheckReceived/success'
