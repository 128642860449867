import React, { Component } from 'react'
import ValidationStep, {
    FAILED,
    IDLE,
    STARTED,
    VALIDATED
} from './baseComponent/ValidationStep'
import { getMissingMediaTrackingForChecksStatusSelector } from '../../core/checkSelectors'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { TIMEOUT_TRACKING_MS } from '../../DocumentationUrls'

class MediaTrackingCheck extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isTimeoutStarted: false,
            onTimeout: false,
            timeoutId: -1
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            nextProps.trackingArray !== this.props.trackingArray &&
            nextProps.trackingArray.length > 0 &&
            nextProps.missingMediaTracking.length !== 0
        ) {
            this.maybeStartTimeout()
        }

        if (
            nextProps.missingMediaTracking.length === 0 &&
            this.state.timeoutId !== -1
        ) {
            clearTimeout(this.state.timeoutId)
            this.setState({ ...this.state, timeoutId: -1 })
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeoutId)
    }

    getStatus(missingMediaTracking) {
        if (missingMediaTracking.length === 0) {
            return VALIDATED
        }

        if (this.state.onTimeout) {
            return FAILED
        }

        if (this.state.isTimeoutStarted) {
            return STARTED
        }

        return IDLE
    }

    maybeStartTimeout() {
        if (!this.state.isTimeoutStarted) {
            let timeoutId = setTimeout(() => {
                this.onTimeout()
            }, TIMEOUT_TRACKING_MS)
            this.setState({
                timeoutId: timeoutId,
                isTimeoutStarted: true
            })
        }
    }

    onTimeout() {
        this.setState({ onTimeout: true })
    }

    getErrorMessage(missingMediaTracking) {
        let errorMessage = ''
        if (missingMediaTracking.length > 0) {
            const missingTrackingName = missingMediaTracking[0]
            errorMessage =
                'Tracking ' + missingTrackingName + ' has not been received. '
            switch (missingTrackingName) {
                case 'impression':
                case 'start':
                    return (
                        <Typography>
                            {errorMessage} Make sure that the ad was visible on
                            the screen.
                            <br />
                            If you can see the ad but it does not start, check
                            your console logs for more information.
                            <br />
                            Please make sure that no transparent overlay on the
                            article that would block the ad experience from
                            starting.
                        </Typography>
                    )
                case 'firstQuartile':
                case 'midPoint':
                case 'thirdQuartile':
                case 'complete':
                    return (
                        <Typography>
                            {errorMessage} You need to wait for the end of the
                            video so we can check that the ad played entirely.
                        </Typography>
                    )
                default:
                    return (
                        <Typography>
                            {errorMessage} This should not happen.{' '}
                        </Typography>
                    )
            }
        }
    }

    render() {
        const { missingMediaTracking } = this.props
        const status = this.getStatus(missingMediaTracking)
        return (
            <ValidationStep
                status={this.getStatus(missingMediaTracking)}
                text={'Media progress are received'}
                errorComponent={
                    status === FAILED &&
                    this.getErrorMessage(missingMediaTracking)
                }
            />
        )
    }
}

const mapStateToProps = state => ({
    missingMediaTracking: getMissingMediaTrackingForChecksStatusSelector(state)
})

export default connect(mapStateToProps)(MediaTrackingCheck)
