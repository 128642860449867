import React, { Component } from 'react'
import ValidationStep, {
    FAILED,
    IDLE,
    STARTED,
    VALIDATED
} from './baseComponent/ValidationStep'
import { getMissingTeadsTrackingForChecksStatusSelector } from '../../core/checkSelectors'
import { connect } from 'react-redux'
import {
    TIMEOUT_TRACKING_MS,
    DocumentationUrl
} from '../../DocumentationUrls'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

export class TeadsTrackingsCheck extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isTimeoutStarted: false,
            onTimeout: false,
            timeoutId: -1
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            nextProps.trackingArray !== this.props.trackingArray &&
            nextProps.trackingArray.length > 0 &&
            nextProps.missingTeadsTrackings.length !== 0
        ) {
            this.maybeStartTimeout()
        }

        if (
            nextProps.missingTeadsTrackings.length === 0 &&
            this.state.timeoutId !== -1
        ) {
            clearTimeout(this.state.timeoutId)
            this.setState({ timeoutId: -1 })
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeoutId)
    }

    getStatus(missingMediaTrackings) {
        if (missingMediaTrackings.length === 0) {
            return VALIDATED
        }

        if (this.state.onTimeout) {
            return FAILED
        }

        if (this.state.isTimeoutStarted) {
            return STARTED
        }

        return IDLE
    }

    maybeStartTimeout() {
        if (!this.state.isTimeoutStarted) {
            let timeoutId = setTimeout(() => {
                this.onTimeout()
            }, TIMEOUT_TRACKING_MS)
            this.setState({
                timeoutId: timeoutId,
                isTimeoutStarted: true
            })
        }
    }

    onTimeout() {
        this.setState({ onTimeout: true })
    }

    getErrorMessage(missingTeadsTrackings) {
        let errorMessage = ''
        if (missingTeadsTrackings.length > 0) {
            const missingTrackingName = missingTeadsTrackings[0]
            errorMessage =
                'Tracking ' + missingTrackingName + ' has not been received. '
            switch (missingTrackingName) {
                case 'placementCall':
                    return (
                        <Typography>
                            {errorMessage} Please check that the load() is
                            correctly called.
                        </Typography>
                    )
                case 'adReached':
                    const { os, sdkVersion } = this.props
                    const documentUrl = DocumentationUrl.Container.urlBasedOnVersion(sdkVersion, os);
                    return (
                        <>
                            <Typography>{errorMessage}</Typography>
                            <Typography>
                                Is the Teads Ad View present in the view
                                hierarchy when scrolling to the ad? We need
                                either the Teads Ad View present in order to
                                check the ad visibility and fire the adReached
                                tracking. See {''}
                                <Link
                                    href={documentUrl}
                                    target="_blank"
                                >
                                    documentation
                                </Link>
                            </Typography>
                        </>
                    )
                default:
                    return (
                        <Typography>
                            {errorMessage} This should not happen.{' '}
                        </Typography>
                    )
            }
        }
    }

    render() {
        const { missingTeadsTrackings } = this.props
        const status = this.getStatus(missingTeadsTrackings)
        return (
            <ValidationStep
                status={status}
                text={
                    status === FAILED
                        ? 'Teads trackings not received'
                        : 'Teads trackings received'
                }
                errorComponent={
                    status === FAILED &&
                    this.getErrorMessage(missingTeadsTrackings)
                }
            />
        )
    }
}

const mapStateToProps = state => ({
    missingTeadsTrackings: getMissingTeadsTrackingForChecksStatusSelector(state)
})

export default connect(mapStateToProps)(TeadsTrackingsCheck)
