export const compareVersions = (refVersion, versionToCheck) => {
    const refVersionArray = getVersionNumber(refVersion)
    const versionToCheckArray = getVersionNumber(versionToCheck)

    for (let i = 0; i < versionToCheckArray.length; i++) {
        const toCheckSequenceIdentifier = versionToCheckArray[i] || 0
        const refSequenceIdentifier = refVersionArray[i] || 0
        if (toCheckSequenceIdentifier < refSequenceIdentifier) {
            return -1
        } else if (toCheckSequenceIdentifier > refSequenceIdentifier) {
            return 1
        }
    }
    return 0
}

export const isVersionBelow = (refVersion, versionToCheck) =>
    compareVersions(refVersion, versionToCheck) === -1

export const isVersionAbove = (refVersion, versionToCheck) =>
    compareVersions(refVersion, versionToCheck) === 1

const getVersionNumber = version => {
    return version
        ? version
              .split('.')
              .map(sequenceIdentifier => parseInt(sequenceIdentifier, 10) || 0)
        : []
}

export const extractVersionNumber = version => {
    const regex = /(\d+\.\d+\.\d+)/ // x.y.z format
    const match = regex.exec(version);
    return match ? match[0] : '';
}
