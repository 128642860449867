import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
    layout: {
        position: 'relative',
        width: '100%',
        minHeight: 'calc(100vh - 80px)',
        boxSizing: 'border-box',
        [theme.breakpoints.up('lg')]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        background: '#F5F5F5',
        top: '-15px',
        padding: '46px 32px',
        display: 'flex',
        flexDirection: 'row'
    },
    test: {
        color: '#7BB8E6',
        margin: '64px 0 0',
        fontSize: '250px',
        textAlign: 'center',
        alignSelf: 'center',
        width: '100%',
        order: '1'
    }
})

class Page404 extends Component {
    render() {
        const { classes } = this.props
        return (
            <div className={classes.layout}>
                <Typography variant="h1" gutterBottom className={classes.test}>
                    404 ARE YOU LOST?
                </Typography>
            </div>
        )
    }
}

export default withStyles(styles)(Page404)
