import React, { Component } from 'react'
import ValidationStep, {
    FAILED,
    IDLE,
    VALIDATED
} from './baseComponent/ValidationStep'
import {
    DocumentationUrl

} from '../../DocumentationUrls'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

function validURL(str) {
    var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ) // fragment locator
    return !!pattern.test(str)
}
export class RefererUrlCheck extends Component {
    getStatus(contentUrl) {
        if (contentUrl) {
            return validURL(contentUrl) ? VALIDATED : FAILED
        }
        return IDLE
    }

    getErrorMessage() {
        const { os, sdkVersion } = this.props
        const documentUrl = DocumentationUrl.BrandSafety.urlBasedOnVersion(sdkVersion, os);
        return (
            <Typography>
                You need to pass the page url to Teads SDK. This may be the
                sharing url from a given content (article, sport result). This
                url should be available from any country, see{' '}
                <Link
                    href={documentUrl}
                    target="_blank"
                >
                    here
                </Link>
            </Typography>
        )
    }

    render() {
        const { contentUrl } = this.props
        const status = this.getStatus(contentUrl)
        return (
            <ValidationStep
                status={status}
                text={'Page URL (or article url) is valid'}
                errorComponent={status === FAILED && this.getErrorMessage()}
            />
        )
    }
}
