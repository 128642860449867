import React, { Component } from 'react'
import ValidationStep, {
    FAILED,
    IDLE,
    VALIDATED
} from './baseComponent/ValidationStep'
import Link from '@material-ui/core/Link'
import { DocumentationUrl } from '../../DocumentationUrls'
import Typography from '@material-ui/core/Typography'
import { extractVersionNumber } from './VersionUtils'

export class SDKVersionCheck extends Component {
    getStatus(currentSdkVersion, latestSDKVersion) {
        if (currentSdkVersion && latestSDKVersion) {
            const current = extractVersionNumber(currentSdkVersion)
            if (current === latestSDKVersion) {
                return VALIDATED
            }
            return FAILED
        }
        return IDLE
    }

    getErrorMessage(currentSdkVersion, latestSDKVersion) {
        const { os } = this.props
        return (
            <Typography>
                The Teads SDK version (v{currentSdkVersion}) need to be update
                to the v{latestSDKVersion}, see{' '}
                <Link
                    href={DocumentationUrl.Root.currentUrl(os)} //here we use the current version of the SDK because is referring as an update
                    target="_blank"
                >
                    here
                </Link>
            </Typography>
        )
    }

    render() {
        const { currentSdkVersion, latestSDKVersion } = this.props
        const status = this.getStatus(currentSdkVersion, latestSDKVersion)
        return (
            <ValidationStep
                status={status}
                text={'SDK version is up to date'}
                errorComponent={
                    status === FAILED &&
                    this.getErrorMessage(currentSdkVersion, latestSDKVersion)
                }
            />
        )
    }
}
