import { createSelector } from 'reselect'

const getValidationSession = state => state.validation

export const getValidationSessionSelector = state =>
    getValidationSession(state).id ? getValidationSession(state) : null

export const getValidationSessionIdSelector = state => getValidationSessionSelector(state).id

export const getValidationDataSelector = state =>
    getValidationSessionSelector(state).data

export const getValidationSessionInfoSelector = state =>
    getValidationDataSelector(state).sessionInfo

export const getSessionDateSelector = state =>
    getValidationDataSelector(state).timestamp

export const getValidationChecksSelector = state =>
    getValidationDataSelector(state).checks

export const getTrackingsForChecksSelector = state =>
    getValidationChecksSelector(state).trackings

export const getLatestSDKVersionSelector = state =>
    getValidationDataSelector(state).latestSdkVersion

export const getAppAdsTxtResultSelector = state =>
    getValidationDataSelector(state).appTxtResult

export const isSessionStartedSelector = state =>
    getValidationSession(state).sessionStarted

export const getAppIdSelector = state =>
    getValidationSessionInfoSelector(state).appId

export const getOSSelector = state => getValidationSessionInfoSelector(state).os

export const getCurrentSDKVersionSelector = state => {
    try {
        return getValidationChecksSelector(state).adRequest.request.body
            .sdkVersion
    } catch (error) {
        return null
    }
}

export const getContentUrlSelector = state => {
    try {
        return getValidationChecksSelector(state).adRequest.request.body
            .windowReferrerUrl
    } catch (error) {
        return null
    }
}

export const getPIDSelector = state => {
    try {
        return getValidationChecksSelector(state).adRequest.request.body
            .tag
    } catch (error) {
        return null
    }
}

export const getGdprJsonSelector = state => {
    try {
        return getValidationChecksSelector(state).adRequest.request.body.gdprIab
    } catch (error) {
        return null
    }
}

export const getUSPrivacySelector = state => {
    try {
        return getValidationChecksSelector(state).adRequest.request.body
            .usPrivacy
    } catch (error) {
        return null
    }
}

export const getCreativesResizeSelector = state => {
    try {
        return getValidationChecksSelector(state).creatives
    } catch (error) {
        return null
    }
}

export const getSessionLoadError = state =>
    getValidationSession(state).sessionLoadError

// Memoized

export const getTrackingsMapForChecksSelector = createSelector(
    getTrackingsForChecksSelector,
    trackings => {
        if (!trackings || trackings.length === 0) {
            return {}
        }

        const result = {}
        trackings.forEach(item => {
            result[item.action] = result[item.action] || []
            result[item.action].push(item)
        })

        return result
    }
)
