const INIT_WEBSOCKET_CLIENT = 'INIT_WEBSOCKET_CLIENT'
const RESET_WEBSOCKET_CLIENT = 'RESET_WEBSOCKET_CLIENT'

export const initWebsocketClient = websocketClient => ({
    type: INIT_WEBSOCKET_CLIENT,
    data: websocketClient
})

export const resetWebsocketClient = () => ({
    type: RESET_WEBSOCKET_CLIENT
})

const initState = {
    websocketClient: null
}

const testsReducer = (state = initState, action) => {
    switch (action.type) {
        case INIT_WEBSOCKET_CLIENT:
            return {
                websocketClient: action.data
            }
        case RESET_WEBSOCKET_CLIENT:
            return {
                websocketClient: null
            }
        default:
            return state
    }
}

export default testsReducer
