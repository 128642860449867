import {
    TEST_SUBMIT_SUCCESS,
    ADREQUEST_RECEIVED,
    APPADS_TXT_ERROR,
    APPADS_TXT_RECEIVED,
    GET_SESSION_ERROR,
    GET_SESSION_SUCCESS,
    SDK_VERSION_RECEIVED,
    TEST_SUBMIT_ERROR,
    TRACKING_RECEIVED,
    CREATIVE_CHECK_RECEIVED
} from './validationActionTypes'

const initState = {
    id: null,
    sessionStarted: false,
    sessionLoadError: null,
    testSubmitError: null,
    data: {
        timestamp: null,
        sessionInfo: {
            appId: null,
            appVersion: null,
            country: null,
            device: null,
            deviceFamily: null,
            language: null,
            network: null,
            os: null,
            osVersion: null,
            screenHeight: null,
            screenWidth: null,
            sdkVersion: null,
            contextInfo: {
                mediation: null,
                viewclass: null,
                hasSubscribedToAdResizing: null,
                version: null
            }
        },
        checks: {
            adRequest: null,
            trackings: [],
            creatives: []
        }
    }
}

const validationReducer = (state = initState, { payload, type }) => {
    switch (type) {
        case GET_SESSION_SUCCESS:
            return {
                ...state,
                id: payload.id,
                data: {
                    ...state.data,
                    sessionInfo: {
                        ...payload.sessionInfo
                    },
                    timestamp: payload.timestamp
                }
            }
        case GET_SESSION_ERROR:
            return {
                ...state,
                sessionLoadError: payload
            }
        case TRACKING_RECEIVED: {
            const key = 'checks'
            return {
                ...state,
                data: {
                    ...state.data,
                    [key]: {
                        ...state.data[key],
                        trackings: payload.trackings
                    }
                }
            }
        }
        case CREATIVE_CHECK_RECEIVED: {
            const key = 'checks'
            return {
                ...state,
                data: {
                    ...state.data,
                    [key]: {
                        ...state.data[key],
                        creatives: payload.creatives
                    }
                }
            }
        }
        case ADREQUEST_RECEIVED: {
            const key = 'checks'
            return {
                ...state,
                data: {
                    ...state.data,
                    [key]: {
                        ...state.data[key],
                        adRequest: payload.adRequest
                    }
                }
            }
        }
        case SDK_VERSION_RECEIVED:
            return {
                ...state,
                data: {
                    ...state.data,
                    latestSdkVersion: payload.latestSdkVersion
                }
            }
        case APPADS_TXT_ERROR:
            return {
                ...state,
                data: {
                    ...state.data,
                    appTxtResult: payload
                }
            }
        case APPADS_TXT_RECEIVED:
            return {
                ...state,
                data: {
                    ...state.data,
                    appTxtResult: payload
                }
            }
        case TEST_SUBMIT_SUCCESS:
            return {
                ...state,
                sessionStarted: true
            }
        case TEST_SUBMIT_ERROR:
            return {
                ...state,
                testSubmitError: payload
            }
        default:
            return state
    }
}

export default validationReducer
