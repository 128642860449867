import { createSelector } from 'reselect'
import { getTrackingsMapForChecksSelector } from './validationSelectors'

export const getMissingMediaTrackingForChecksStatusSelector = createSelector(
    getTrackingsMapForChecksSelector,
    trackingMap => {
        const trackingsToCheck = [
            'impression',
            'start',
            'firstQuartile',
            'midpoint',
            'thirdQuartile',
            'complete'
        ]

        for (let i = 1; i < 31; i++) {
            trackingsToCheck.push(`progress-${i}`)
        }

        return trackingsToCheck.filter(neededTracking => {
            return (
                !trackingMap[neededTracking] ||
                trackingMap[neededTracking].length === 0
            )
        })
    }
)

export const getMissingTeadsTrackingForChecksStatusSelector = createSelector(
    getTrackingsMapForChecksSelector,
    trackingMap => {
        const missingTrackings = []
        const trackingToCheck = [
            'placementCall',
            'adReached',
            'adAvailable-success'
        ]

        trackingToCheck.forEach(tracking => {
            if (!(trackingMap[tracking] && trackingMap[tracking].length > 0)) {
                missingTrackings.push(tracking)
            }
        })

        return missingTrackings
    }
)

export const isAdVailableSuccessForChecksReceivedSelector = createSelector(
    getTrackingsMapForChecksSelector,
    trackings => {
        return !!trackings['adAvailable-success']
    }
)

export const isStartForChecksReceivedSelector = createSelector(
    getTrackingsMapForChecksSelector,
    trackings => {
        return !!trackings['start']
    }
)

export const isCompleteForChecksReceivedSelector = createSelector(
    getTrackingsMapForChecksSelector,
    trackings => {
        return !!trackings['complete']
    }
)
