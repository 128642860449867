import React, { Component } from 'react'
import ValidationStep, {
    FAILED,
    IDLE,
    VALIDATED,
    WARNING
} from './baseComponent/ValidationStep'
import {
    DocumentationUrl,
} from '../../DocumentationUrls'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { ConsentString } from 'consent-string'
import { TCString } from '@iabtcf/core'

export class GDPRCheck extends Component {
    getResult(gdprJson) {
        if (!gdprJson) {
            return {
                status: IDLE
            }
        }

        try {
            const gdprParsed = JSON.parse(gdprJson)
            const gdprData = {
                status: gdprParsed.status,
                consent: gdprParsed.consent,
                policyVersion: gdprParsed.apiVersion, // map to policy version
                cmpId: gdprParsed.cmpId
            }

            if (gdprData.status === 0) {
                return {
                    status: VALIDATED,
                    message: 'GDPR does not apply.'
                }
            } else if (gdprData.status < 20) {
                const consentStringLength = gdprData.consent.length
                if (consentStringLength === 0) { // Consent should not be empty
                    return {
                        status: FAILED,
                        message: 'The consent string is empty.'
                    }
                } else {
                    try {
                        if (gdprData.policyVersion === 1) { // TCF Policy version check
                            const consentStringParsed = new ConsentString(
                                gdprData.consent
                            )
                            if (
                                consentStringParsed.getCmpId() &&
                                consentStringParsed.getCmpVersion()
                            ) {
                                return {
                                    status: FAILED,
                                    message:
                                        'Your are currently using TCF v1. Note that before August 15, 2020 it is required to switch over to TCF v2.0.'
                                }
                            }
                            return {
                                status: FAILED,
                                message:
                                    'The consent string is missing some data, there is probably something wrong with the CMP you are using.'
                            }
                        } else if (gdprData.policyVersion >= 2) { // TCF Policy version check
                            const tcStringDecoded = TCString.decode(
                                gdprData.consent
                            )
                            if (tcStringDecoded.version === 1) { // TCF API version check
                                return {
                                    status: FAILED,
                                    message: 'Your are currently using TCF v1. Note that before August 15, 2020 it is required to switch over to TCF v2.0.'
                                }
                            } else if (tcStringDecoded > 2) {
                                return {
                                    status: FAILED,
                                    message: `Teads is not compliant with TCF API version ${tcStringDecoded.version}.`
                                }
                            }
                            if (!tcStringDecoded.vendorConsents.has(132)) { // Teads vendor should be present
                                return {
                                    status: FAILED,
                                    message:
                                        'The decoded consent string does not contain Teads Vendor 132.'
                                }
                            }

                            const expectedPurposes = [1, 3, 4, 7, 9, 10]; // All purposes except 2, 5, 6, 8 should be present
                            const missingPurposes = []
                            for (const purpose of expectedPurposes) {
                                if (!tcStringDecoded.purposeConsents.has(purpose)) {
                                    missingPurposes.push(purpose)
                                }
                            }
                            if (missingPurposes.length > 0) {
                                return {
                                    status: FAILED,
                                    message:
                                        'The decoded consent string does not contain the following purposes, which is mandatory for Teads: ' + missingPurposes.join(", ")
                                }
                            }

                            if (tcStringDecoded.cmpId === gdprData.cmpId) { // CPM ids should be the same
                                return {
                                    status: VALIDATED,
                                    message: 'Your are currently using TCF v2. All validations were accepted.'
                                }
                            } else {
                                return {
                                    status: FAILED,
                                    message:
                                        'The decoded consent string return a cmp ID different from the one you provided.'
                                }
                            }
                        } else {
                            return {
                                status: FAILED,
                                message: 'The TCF Policy Version is not found.'
                            }
                        }
                    } catch (exception) {
                        return {
                            status: FAILED,
                            message:
                                'The consent string is not formatted correctly.'
                        }
                    }
                }
            } else {
                return {
                    status: WARNING,
                    message:
                        "Your application doesn't apply GDPR requirements. It's mandatory in European countries and for all European citizens. If you are not under GDPR regulation please ignore this section."
                }
            }
        } catch (e) {
            return {
                status: FAILED,
                message: 'The consent string cannot be parsed.'
            }
        }
    }

    displayDocumentationLink(result, os, sdkVersion) {
        const documentUrl = DocumentationUrl.GDPR.urlBasedOnVersion(sdkVersion, os);
        return (
            result.status !== VALIDATED && (
                <span>
                    , check{' '}
                    <Link
                        href={documentUrl}
                        target="_blank"
                    >
                        here
                    </Link>
                </span>
            )
        )
    }

    render() {
        const { gdprJson, os, sdkVersion } = this.props
        const result = this.getResult(gdprJson)
        return (
            <ValidationStep
                status={result.status}
                text={'GDPR Compliant'}
                errorComponent={
                    result.message && (
                        <Typography>
                            {result.message}
                            {this.displayDocumentationLink(result, os, sdkVersion)}
                        </Typography>
                    )
                }
            />
        )
    }
}
