import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import {
    isAdVailableSuccessForChecksReceivedSelector,
    isCompleteForChecksReceivedSelector,
    isStartForChecksReceivedSelector
} from '../../../core/checkSelectors'
import { TIMEOUT_TRACKING_MS } from '../../../DocumentationUrls'

const styles = () => ({
    container: {
        backgroundColor: '#DDD',
        padding: '15px 15px 15px',
        margin: '10px',
        maxWidth: '350px'
    },
    icon: {
        marginRight: 15
    }
})

const indications = {
    waitingAdAvailableSuccess: 'Teads Ad is loading',
    waitingStart: 'Scroll to the ad, a video should play',
    waitingComplete: 'Wait for the end of the video'
}

function WaitingIndication({
    classes,
    completeReceived,
    startReceived,
    adAvailableSuccessReceived
}) {
    const [isTimeout, setIsTimeout] = useState(false)

    const getIndication = () => {
        if (isTimeout) {
            return null
        }
        if (completeReceived) {
            return null
        }
        if (startReceived) {
            return indications.waitingComplete
        }
        if (adAvailableSuccessReceived) {
            return indications.waitingStart
        }
        return indications.waitingAdAvailableSuccess
    }

    useEffect(() => {
        let timer1 = setTimeout(() => {
            setIsTimeout(true)
        }, TIMEOUT_TRACKING_MS)

        // this will clear Timeout when component unmont like in willComponentUnmount
        return () => {
            clearTimeout(timer1)
        }
    }, [completeReceived, startReceived, adAvailableSuccessReceived])

    const indication = getIndication()

    return (
        <Collapse in={!!indication}>
            <div className={classes.container}>
                <CircularProgress size={16} className={classes.icon} />{' '}
                {indication}
            </div>
        </Collapse>
    )
}

const mapStateToProps = state => ({
    adAvailableSuccessReceived: isAdVailableSuccessForChecksReceivedSelector(
        state
    ),
    startReceived: isStartForChecksReceivedSelector(state),
    completeReceived: isCompleteForChecksReceivedSelector(state)
})

const mapDispatchToProps = Object.assign({}, {})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(WaitingIndication))
