const config = {
    googleAnalyticsId: 'UA-71200116-9',
    apiBaseUrl: 'https://e7l4l2fuj5.execute-api.eu-west-1.amazonaws.com/prod',
    apiWsBaseUrl: 'wss://nelp5zfcp5.execute-api.eu-west-1.amazonaws.com/prod',
    mediaFileVideo: 'https://misc.teads.tv/inapp/ad/teads-landscape.mp4',
    sumoCollector: 'https://l.teads.tv/inapp/validation-tool',
    githubToken: ''
}

export default config
