import { applyMiddleware, compose, createStore } from 'redux'
import rootReducer from './rootReducer'
import thunk from 'redux-thunk'
import { googleAnalytics } from './reactGAMiddlewares'

export default function init() {
    const middleware = [thunk, googleAnalytics]
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    // if (module.hot) {
    //     // Enable Webpack hot module replacement for reducers
    //     module.hot.accept('../reducers', () => {
    //         store.replaceReducer(createRootReducer(require('../reducers')))
    //     })
    // }

    return createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...middleware))
    )
}
