import React, { Component } from 'react'
import ValidationStep, {
    FAILED,
    IDLE,
    VALIDATED
} from './baseComponent/ValidationStep'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import {
    DocumentationUrl
} from '../../DocumentationUrls'

export class AppAdsCheck extends Component {

    getResult(appAdsResult, appId, os, sdkVersion) {
        const documentUrl = DocumentationUrl.GDPR.urlBasedOnVersion(sdkVersion, os);
        if (!appAdsResult) {
            return {
                status: IDLE
            }
        }
        switch (appAdsResult) {
            case 'no-matching-app-itunes-lookup': {
                return {
                    status: FAILED,
                    docUrl: documentUrl,
                    message: `We were unable to find your app (${appId}) on the App Store. If you think there is an issue, contact support-sdk@teads.tv with your Session Infos.`
                }
            }
            case 'no-seller-url-itunes-lookup': {
                return {
                    status: FAILED,
                    docUrl: documentUrl,
                    message:
                        'We are not able to find your Developer Website from Apple. Did you filled the Marketing URL on App Store Connect?'
                }
            }
            case 'playstore-url-load-fail': {
                return {
                    status: FAILED,
                    docUrl: documentUrl,
                    message:
                        'We are not able to contact the Play Store, request timeout. Please try again later.'
                }
            }
            case 'no-matching-app-playstore': {
                return {
                    status: FAILED,
                    docUrl: documentUrl,
                    message: `We are not able to find your app (${appId}) on the Google Play Store. Are you using a custom development package? Is the app published on the store?`
                }
            }
            case 'developer-url-not-found-android': {
                return {
                    status: FAILED,
                    docUrl: documentUrl,
                    message:
                        'We are not able to find the Developer Url / Website on your Play Store page. Did you filled the "Web Site" field on the store?'
                }
            }
            case 'developer-url-found-but-no-eligible-crawl-url': {
                return {
                    status: FAILED,
                    docUrl: documentUrl,
                    message:
                        'We found your developer url but were not able to find a proper url to crawl.'
                }
            }
            case 'appads-txt-notfound': {
                return {
                    status: FAILED,
                    docUrl:
                        'https://iabtechlab.com/wp-content/uploads/2019/03/app-ads.txt-v1.0-final-.pdf',
                    message:
                        'We are not able to find your app-ads.txt file. It may not exist or the server may not be configured correctly. You can find more information on the IAB App Ads Txt Specs'
                }
            }
            case 'teads-not-found': {
                return {
                    status: FAILED,
                    docUrl: documentUrl,
                    message:
                        'We found your app-ads.txt but it did not include Teads. Contact your Teads Publisher Manager for more info. Also, "teads.com" is not a valid domain.'
                }
            }
            case 'teadsValid': {
                return {
                    status: VALIDATED
                }
            }
            case 'notValid':
            default:
                return {
                    status: FAILED,
                    docUrl: documentUrl,
                    message:
                        'We are unable to check if you are compliant with app-ads.txt. If you think there is an issue, contact support-sdk@teads.tv with your Session Infos.'
                }
        }
    }

    render() {
        const { appAdsResult, appId, os, sdkVersion } = this.props
        const result = this.getResult(appAdsResult, appId, os, sdkVersion)
        return (
            <ValidationStep
                text={'App-ads.txt include Teads.tv'}
                status={result.status}
                errorComponent={
                    result.message && (
                        <Typography>
                            {result.message} check{' '}
                            {result.docUrl && (
                                <Link
                                    href={result.docUrl}
                                    target="_blank"
                                >
                                    {' '}
                                    here.
                                </Link>
                            )}
                        </Typography>
                    )
                }
            />
        )
    }
}
