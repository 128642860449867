import {isVersionBelow} from "./tests/checks/VersionUtils";

const DOCUMENTATION_BASE = 'https://support.teads.tv/support/solutions/articles/'

export class DocumentationUrl {
    constructor(urlLegacy, urliOS, urlAndroid) {
        this.urlLegacy = urlLegacy
        this.urliOS = urliOS
        this.urlAndroid = urlAndroid
    }

    urlBasedOnVersion(sdkVersion, os) {
        return isVersionBelow('5.0.0', sdkVersion) ? this.legacyUrl(os) : this.currentUrl(os);
    }

    legacyUrl(os) {
        const url = `${DOCUMENTATION_BASE}${this.urlLegacy}`
        if (os) {
            const lowerCasedOS = os.toLowerCase()
            const idx = url.lastIndexOf('#')
            if (idx > -1) {
                return `${url.substr(0, idx)}-${lowerCasedOS}${url.substr(idx)}`
            } else {
                return `${url}-${lowerCasedOS}`
            }
        }
        return url
    }

    currentUrl(os) {
        if (os && os.toLowerCase() === "android") {
            return `${DOCUMENTATION_BASE}${this.urlAndroid}`
        }
        return `${DOCUMENTATION_BASE}${this.urliOS}`
    }

    static Root = new DocumentationUrl(
        "36000165909",
        "36000315194",
        "36000315194")
    static GDPR = new DocumentationUrl(
        "36000166727",
        "36000314765",
        "36000314741")
    static StandardIntegration = new DocumentationUrl(
        "36000165919",
        "36000314722",
        "36000313226")
    static AdmobResize = new DocumentationUrl(
        "36000166678#ad_resizing",
        "36000314767#ad-resizing",
        "36000314743#ad-resizing")
    static MopubResize = new DocumentationUrl(
        "36000166728#ad_resizing",
        "36000314769#ad-resizing",
        "36000314745#ad-resizing")
    static BrandSafety = new DocumentationUrl(
        `${DocumentationUrl.StandardIntegration.urlLegacy}#brand_safety_and_url`,
        `${DocumentationUrl.StandardIntegration.urliOS}#brand-safety-and-web-content-url`,
        `${DocumentationUrl.StandardIntegration.urlAndroid}#brand-safety-and-web-content-url`)
    static Container = new DocumentationUrl(
        `${DocumentationUrl.StandardIntegration.urlLegacy}#implementation`,
        `${DocumentationUrl.StandardIntegration.urliOS}#implementation`,
        `${DocumentationUrl.StandardIntegration.urlAndroid}#implementation`)
    static AdResize = new DocumentationUrl(
        `${DocumentationUrl.StandardIntegration.urlLegacy}#ad_resizing`,
        `${DocumentationUrl.StandardIntegration.urliOS}#ad-resizing`,
        `${DocumentationUrl.StandardIntegration.urlAndroid}#ad-resizing`)
}

export const TIMEOUT_TRACKING_MS = 60000 //1 min
