const sspJson = {
    ads: [
        {
            settings: {
                values: {
                    animations: {
                        expand: 0,
                        collapse: 0.5
                    },
                    placementFormat: 'inread',
                    placementId: 84242,
                    adType: 'video',
                    brandLogoUrl: '',
                    allowedPlayer: 'any',
                    threshold: 50
                },
                components: {
                    soundButton: {
                        display: true,
                        countdown: 0,
                        type: 'equalizer'
                    },
                    slider: {
                        closeButtonDisplay: false
                    },
                    closeButton: {
                        display: false,
                        countdown: 0
                    },
                    endScreen: {
                        replayButton: {
                            text: 'Replay ad'
                        },
                        callButton: {
                            type: 'learnMore',
                            text: 'Learn more'
                        },
                        autoClose: false,
                        countdown: 6,
                        type: 'simple'
                    },
                    brandLogo: {
                        display: false
                    },
                    callButton: {
                        display: true,
                        countdown: 3,
                        text: 'Learn more'
                    },
                    credits: {
                        display: true,
                        text: '[inRead] invented by Teads',
                        link: 'http://inread-experience.teads.tv'
                    },
                    label: {
                        display: true,
                        text: 'Advertising'
                    }
                },
                behaviors: {
                    soundStart: {
                        type: 'mute'
                    },
                    soundMute: 'threshold',
                    soundOver: 'over',
                    end: 'endscreen',
                    smartPosition: {
                        top: true,
                        corner: true
                    },
                    onlyNative: true,
                    sticky: true,
                    launch: 'auto',
                    videoStart: 'threshold',
                    videoPause: 'threshold'
                }
            },
            type: 'VastXml',
            content: '[VAST]',
            scenario_id: 6974,
            dsp_campaign_id: '197012',
            dsp_creative_id: '176308',
            insertion_id: 197012,
            placement_id: 84242
        }
    ]
}

export default sspJson
