import React, { Component } from 'react'
import * as validationAction from './core/validationActions'
import {
    getSessionLoadError,
    getValidationSessionSelector
} from './core/validationSelectors'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import SessionInfo from './SessionInfo'
import Tests from './tests/Tests'
import Error from '../customComponent/Error'
import Notifications from '../notification/Notifications'

const styles = theme => ({
    layout: {
        position: 'relative',
        width: '100%',
        minHeight: 'calc(100vh - 80px)',
        boxSizing: 'border-box',
        [theme.breakpoints.up('lg')]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        background: '#F5F5F5',
        top: '-15px',
        padding: '46px 32px'
    }
})

class ValidationApp extends Component {
    componentWillMount() {
        if (this.props.match.params.documentId) {
            const id = this.props.match.params.documentId
            this.props.getSession(id)
        }
    }

    render() {
        const { validationSession, sessionLoadError, classes } = this.props
        return (
            <div className={classes.layout}>
                {sessionLoadError && (
                    <Error
                        error="Unable to load the session"
                        errorDetail={sessionLoadError}
                    />
                )}
                {!sessionLoadError && validationSession && <SessionInfo />}
                {!sessionLoadError && validationSession && <Tests />}
                <Notifications />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    validationSession: getValidationSessionSelector(state),
    sessionLoadError: getSessionLoadError(state)
})

const mapDispatchToProps = Object.assign({}, validationAction)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ValidationApp))
