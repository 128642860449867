import { Component } from 'react'
import React from 'react'
import { withStyles } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import logoHeader from './logo-header.svg'
import {DocumentationUrl} from "../validation/DocumentationUrls";
import {connect} from "react-redux";

const styles = theme => ({
    appBar: {
        backgroundColor: '#232833',
        position: 'relative'
    },
    layout: {
        width: '100%',
        boxSizing: 'border-box',
        [theme.breakpoints.up('lg')]: {
            width: 900,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        display: 'flex',
        justifyContent: 'space-between'
    },
    toolbarTitle: {
        marginTop: '-10px',
        fontSize: '15px'
    }
})

class Header extends Component {
    render() {
        const { classes } = this.props
        const documentUrl = DocumentationUrl.Root.urlBasedOnVersion(classes.sdkVersion, classes.os)
        return (
            <AppBar
                position="static"
                color="default"
                className={classes.appBar}
            >
                <Toolbar className={classes.layout}>
                    <div className="logoContainer">
                        <img
                            src={logoHeader}
                            width={70}
                            height={40}
                            className={classes.logo}
                            alt="logo"
                        />
                        <Typography
                            variant="h5"
                            color="secondary"
                            className={classes.toolbarTitle}
                        >
                            inApp SDK Validation
                        </Typography>
                    </div>
                    <div>
                        <Button
                            color="secondary"
                            target="_blank"
                            href={documentUrl}
                        >
                            Documentation
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }
}

const mapStateToProps = state => ({
    sdkVersion: state.validation.data.sessionInfo.sdkVersion,
    os: state.validation.data.sessionInfo.os,
})

export default connect(
    mapStateToProps
)(withStyles(styles, { withTheme: true })(Header))

