import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import ValidationApp from './component/validation/ValidationApp'
import * as serviceWorker from './serviceWorker'
import { initGA } from './reactGAMiddlewares'
import configureStore from './configureStore'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Page404 from './component/Page404'
import { createMuiTheme } from '@material-ui/core'
import Header from './component/layout/Header'
import CssBaseline from '@material-ui/core/CssBaseline'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
initGA()

const store = configureStore()

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#03bbff',
            main: '#03b2f6',
            dark: '#0373ab',
            contrastText: '#fff'
        },
        secondary: {
            main: '#ffffff'
        }
    },
    typography: {
        useNextVariants: true
    },
    spacing: {
        default: 16
    }
})

const render = Component => {
    return ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <>
                    <CssBaseline />
                    <MuiThemeProvider theme={theme}>
                        <div className="App">
                            <Header />
                            <Switch>
                                <Route
                                    path="/:documentId"
                                    component={Component}
                                />
                                <Route component={Page404} />
                            </Switch>
                        </div>
                    </MuiThemeProvider>
                </>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    )
}

render(ValidationApp)

if (module.hot) {
    module.hot.accept('./component/validation/ValidationApp', () => {
        const NextApp = require('./component/validation/ValidationApp').default
        render(NextApp)
    })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
