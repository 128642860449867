import { combineReducers } from 'redux'
import validationReducer from './component/validation/core/validationReducer'
import notificationReducer from './component/notification/notificationReducer'
import testsReducer from './component/validation/tests/testsReducer'

const rootReducer = combineReducers({
    validation: validationReducer,
    notifications: notificationReducer,
    tests: testsReducer
})

export default rootReducer
