import config from '../../config'

export function trackSessionStarted(sessionID, sessionInfo) {
    const img = new Image()
    const tracking = {
        event: 'started',
        sessionId: sessionID,
        sessionInfo: sessionInfo
    }
    img.src = config.sumoCollector + '?' + JSON.stringify(tracking)
}
