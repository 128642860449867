import React, { Component } from 'react'
import ValidationStep, {
    FAILED,
    VALIDATED,
    WARNING
} from './baseComponent/ValidationStep'
import {
    DocumentationUrl
} from '../../DocumentationUrls'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

export class USPrivacyCheck extends Component {
    getResult(usPrivacy, os, sdkVersion) {
        const documentUrl = DocumentationUrl.GDPR.urlBasedOnVersion(sdkVersion, os);
        if (usPrivacy) {
            const ccpaRegex = new RegExp('[0-9][Y|N|-]{3}')
            if (ccpaRegex.test(usPrivacy)) {
                return {
                    status: VALIDATED
                }
            }
            return {
                status: FAILED,
                docUrl:
                    'https://github.com/InteractiveAdvertisingBureau/USPrivacy/blob/master/CCPA/US%20Privacy%20String.md#us-privacy-string-format',
                message: `The provided CCPA (${usPrivacy}) is not in the correct format`
            }
        }

        return {
            status: WARNING,
            docUrl: documentUrl,
            message:
                "Your application doesn't apply CCPA requirements. It's mandatory in California's state and for all Californians citizens. If you are not under CCPA regulation please ignore this section"
        }
    }

    render() {
        const { usPrivacy, os, sdkVersion } = this.props
        const result = this.getResult(usPrivacy, os, sdkVersion)
        return (
            <ValidationStep
                status={result.status}
                text={'CCPA Compliant'}
                errorComponent={
                    result.message && (
                        <Typography>
                            {result.message}, check{' '}
                            {result.docUrl && (
                                <Link
                                    href={result.docUrl}
                                    target="_blank"
                                >
                                    {' '}
                                    here.
                                </Link>
                            )}
                        </Typography>
                    )
                }
            />
        )
    }
}
