import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import ValidationStep, {
    FAILED,
    STARTED,
    VALIDATED,
    WARNING
} from './baseComponent/ValidationStep'
import Link from '@material-ui/core/Link'
import {
  DocumentationUrl
} from '../../DocumentationUrls'
import {isVersionBelow} from "./VersionUtils";

const CreativeResizeCheckStatusSuccess = 'Success'
const CreativeResizeCheckStatusAdViewHeightTooLow = 'AdViewHeightTooLow'
const CreativeResizeCheckStatusMediaHeightZero = 'MediaHeightZero'
const CreativeResizeCheckStatusMediaAdViewWidthsMismatch =
    'MediaAdViewWidthsMismatch'
const CreativeResizeCheckStatusContainerHeightTooLow = 'ContainerHeightTooLow'
const CreativeResizeCheckStatusContainerWidthTooLow = 'ContainerWidthTooLow'
const CreativeResizeCheckStatusVisibilityWarning = 'VisibilityWarning'

export class CreativeResizeCheck extends Component {
    getResult(creative, mediation) {
        switch (creative.creativeResizeStatus) {
            case CreativeResizeCheckStatusAdViewHeightTooLow:
                return {
                    status: FAILED,
                    message: 'AdView container height is too narrow'
                }
            case CreativeResizeCheckStatusMediaAdViewWidthsMismatch:
                return {
                    status: FAILED,
                    message: `${this.getResizeMethod(
                        mediation
                    )} is not well implemented`,
                    link: this.getMediationDocumentationLink(mediation)
                }
            case CreativeResizeCheckStatusMediaHeightZero:
                return {
                    status: FAILED,
                    message: 'AdView container height is equal to 0'
                }
            case CreativeResizeCheckStatusContainerHeightTooLow:
                return {
                    status: FAILED,
                    message:
                        'Container View height is too narrow, ad is cropped'
                }
            case CreativeResizeCheckStatusContainerWidthTooLow:
                return {
                    status: FAILED,
                    message: 'Container View width is too narrow, ad is cropped'
                }
            case CreativeResizeCheckStatusVisibilityWarning:
                return {
                    status: WARNING,
                    message: `Ad seems well resized, but visibility is low (${
                        creative.visibility
                    })`
                }
            case CreativeResizeCheckStatusSuccess:
                return {
                    status: VALIDATED
                }
            default:
                return {
                    status: FAILED,
                    message: `Unable to perform creative resize evaluation`
                }
        }
    }

    displayDocumentationLink(result, os, sdkVersion) {
        return (
            result.status !== VALIDATED && (
                <span>
                    , check{' '}
                    <Link
                        href={
                            result.link || DocumentationUrl.AdResize.urlBasedOnVersion(sdkVersion, os)}
                        target="_blank"
                    >
                        here
                    </Link>
                </span>
            )
        )
    }

    getMediationDocumentationLink(mediation, os, sdkVersion) {
        switch (mediation) {
            case 'admob':
                return DocumentationUrl.AdmobResize.urlBasedOnVersion(sdkVersion, os)
            case 'mopub':
                return DocumentationUrl.MopubResize.urlBasedOnVersion(sdkVersion, os);
            default:
                return DocumentationUrl.AdResize.urlBasedOnVersion(sdkVersion, os);
        }
    }

    getResizeMethod(mediation) {
        return mediation
            ? `Mediation (${mediation}) ad resizing`
            : 'Ad resizing'
    }

    getResultContextInfo(contextInfo, os, sdkVersion) {
        return {
            status: FAILED,
            message: `${this.getResizeMethod(
                contextInfo.mediation
            )} is not implemented`,
            link: this.getMediationDocumentationLink(contextInfo.mediation, os, sdkVersion)
        }
    }

    renderResult(result, os, sdkVersion) {
        return (
            <ValidationStep
                status={result.status}
                text={'Creative Resize'}
                errorComponent={
                    result.message && (
                        <Typography>
                            {result.message}
                            {this.displayDocumentationLink(result, os, sdkVersion)}
                        </Typography>
                    )
                }
                isBeta={true}
            />
        )
    }

    render() {
        const {
            creatives,
            os,
            sdkVersion,
            isCompleted,
            contextInfo,
            format
        } = this.props
        if (isVersionBelow('4.7.9', sdkVersion) || (format === 'native')) {
            return null
        }

        const mediation =
            contextInfo && contextInfo.mediation
                ? contextInfo.mediation
                : undefined
        if (mediation && contextInfo.hasSubscribedToAdResizing === '0') {
            const result = this.getResultContextInfo(contextInfo, os, sdkVersion)
            return this.renderResult(result, os, sdkVersion)
        }

        if (!isCompleted) {
            return this.renderResult({ status: STARTED }, os, sdkVersion)
        }

        const failedCreatives = creatives.filter(
            creative => creative.status !== CreativeResizeCheckStatusSuccess
        )
        const lastFailedCreative = failedCreatives[failedCreatives.length - 1]
        const creative = lastFailedCreative
            ? lastFailedCreative
            : { status: CreativeResizeCheckStatusSuccess }

        const result = this.getResult(creative, mediation)
        return this.renderResult(result, os, sdkVersion)
    }
}
