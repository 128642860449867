import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import { startTest } from '../core/validationActions'
import ListenForTracking from './ListenForTracking'
import {
    getAppAdsTxtResultSelector,
    getContentUrlSelector,
    getCurrentSDKVersionSelector,
    getGdprJsonSelector,
    getUSPrivacySelector,
    getLatestSDKVersionSelector,
    getTrackingsForChecksSelector,
    isSessionStartedSelector,
    getAppIdSelector,
    getOSSelector,
    getCreativesResizeSelector,
    getValidationSessionInfoSelector,
    getPIDSelector
} from '../core/validationSelectors'
import TeadsTrackingsCheck from './checks/TeadsTrackingsCheck'
import MediaTrackingCheck from './checks/MediaTrackingCheck'
import { RefererUrlCheck } from './checks/RefererUrlCheck'
import { SDKVersionCheck } from './checks/SDKVersionCheck'
import { GDPRCheck } from './checks/GDPRCheck'
import { CreativeResizeCheck } from './checks/CreativeResizeCheck'
import WaitingIndication from './checks/baseComponent/WaitingIndication'
import Button from '@material-ui/core/Button'
import { AppAdsCheck } from './checks/AppAdsCheck'
import { USPrivacyCheck } from './checks/USPrivacyCheck'
import { isCompleteForChecksReceivedSelector } from '../core/checkSelectors'

const styles = () => ({
    container: {
        backgroundColor: 'inherit',
        margin: '30px 0',
        padding: 0
    }
})

class Tests extends Component {
    onStartChecks = () => {
        this.props.startTest()
    }

    render() {
        const {
            isStarted,
            checksTrackings,
            latestSDKVersion,
            currentSDKVersion,
            contentUrl,
            gdprJson,
            usPrivacy,
            appAdsResult,
            classes,
            appId,
            os,
            creatives,
            isCompleted,
            contextInfo,
            format,
            pid
        } = this.props

        return (
            <>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isStarted}
                    onClick={e => this.onStartChecks(e)}
                >
                    Begin test
                </Button>
                <ListenForTracking />
                <Stepper
                    className={classes.container}
                    activeStep={0}
                    orientation="vertical"
                >
                    <Step key={1}>
                        <StepLabel>Test with an ad (PID: <b>{pid}</b>)</StepLabel>
                        <StepContent>
                            {isStarted && <WaitingIndication />}
                            <RefererUrlCheck
                                contentUrl={contentUrl}
                                os={os}
                                sdkVersion={currentSDKVersion}
                            />
                            <GDPRCheck
                                gdprJson={gdprJson}
                                os={os}
                                sdkVersion={currentSDKVersion}
                            />
                            <USPrivacyCheck
                                usPrivacy={usPrivacy}
                                os={os}
                                sdkVersion={currentSDKVersion}
                            />

                            <SDKVersionCheck
                                currentSdkVersion={currentSDKVersion}
                                latestSDKVersion={latestSDKVersion}
                                os={os}
                            />

                            <AppAdsCheck
                                appAdsResult={appAdsResult}
                                appId={appId}
                                os={os}
                                sdkVersion={currentSDKVersion}
                            />
                            <TeadsTrackingsCheck
                                trackingArray={checksTrackings}
                                os={os}
                                sdkVersion={currentSDKVersion}
                            />
                            <MediaTrackingCheck
                                trackingArray={checksTrackings}
                            />
                            <CreativeResizeCheck
                                creatives={creatives}
                                os={os}
                                sdkVersion={currentSDKVersion}
                                isCompleted={isCompleted}
                                contextInfo={contextInfo}
                                format={format}
                            />
                        </StepContent>
                    </Step>
                </Stepper>
            </>
        )
    }
}

const mapStateToProps = state => ({
    isStarted: isSessionStartedSelector(state),
    currentSDKVersion: getCurrentSDKVersionSelector(state),
    checksTrackings: getTrackingsForChecksSelector(state),
    latestSDKVersion: getLatestSDKVersionSelector(state),
    contentUrl: getContentUrlSelector(state),
    gdprJson: getGdprJsonSelector(state),
    usPrivacy: getUSPrivacySelector(state),
    appAdsResult: getAppAdsTxtResultSelector(state),
    appId: getAppIdSelector(state),
    os: getOSSelector(state),
    creatives: getCreativesResizeSelector(state),
    isCompleted: isCompleteForChecksReceivedSelector(state),
    contextInfo: getValidationSessionInfoSelector(state).contextInfo,
    format: getValidationSessionInfoSelector(state).format,
    pid: getPIDSelector(state)
})

const mapDispatchToProps = Object.assign(
    {},
    {
        startTest: startTest
    }
)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Tests))
