import React, { Component } from 'react'
import { CheckCircle, CircleOutline, CloseCircle, Alert } from 'mdi-material-ui'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FAILED, STARTED, VALIDATED, WARNING } from './ValidationStep'

class ValidationIcon extends Component {
    render() {
        const { status } = this.props

        switch (status) {
            case STARTED:
                return <CircularProgress size={20} />
            case VALIDATED:
                return <CheckCircle style={{ fill: '#00bb00' }} />
            case FAILED:
                return <CloseCircle style={{ fill: '#bb0000' }} />
            case WARNING:
                return <Alert style={{ fill: '#f1c40f' }} />
            default:
                return <CircleOutline style={{ fill: '#bbbbbb' }} />
        }
    }
}

export default ValidationIcon
