import React, { Component } from 'react'
import {
    getPIDSelector,
    getSessionDateSelector, getValidationSessionIdSelector,
    getValidationSessionInfoSelector
} from './core/validationSelectors'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import moment from 'moment'
import {
    Android,
    Apple,
    CalendarRange,
    CellphoneText,
    CellphoneArrowDown,
    CellphoneScreenshot,
    ChevronDown,
    ChevronUp,
    Signal,
    PackageVariant,
    TabletCellphone,
    Translate,
    ZodiacGemini,
    HandshakeOutline,
    StorefrontOutline,
    Identifier
} from 'mdi-material-ui'
import { BUTTON_LIGHTGRAY, BUTTON_LIGHTGRAY_HOVER } from '../../res/colors'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'

const styles = theme => ({
    container: {
        float: 'right',
        borderRadius: '4px',
        transition: 'all 200ms ease-in-out',
        backgroundColor: BUTTON_LIGHTGRAY,
        '&:hover': {
            backgroundColor: BUTTON_LIGHTGRAY_HOVER
        }
    },
    list: {
        margin: 0,
        padding: 0,
        '& li': {
            margin: '10px',
            listStyleType: 'none',
            '& svg': {
                width: '30px',
                marginRight: '15px',
                verticalAlign: 'sub'
            }
        }
    },
    expandButton: {
        width: '100%',
        justifyContent: 'space-between',
        '& svg': {
            verticalAlign: 'sub'
        }
    }
})

class SessionInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isExpanded: true
        }
    }

    onExpandClick = event => {
        this.setState({
            isExpanded: !this.state.isExpanded
        })
    }

    render() {
        const { sessionInfo, timestamp, classes, pid, sessionId } = this.props

        const isExpanded = this.state.isExpanded

        if (!sessionInfo.appId) {
            return ''
        }

        let os
        switch (sessionInfo.os.toLowerCase()) {
            case 'android':
                os = <Android />
                break
            case 'ios':
                os = <Apple />
                break
            default:
                os = <ZodiacGemini />
                break
        }

        return (
            <div className={classes.container}>
                <Button
                    onClick={e => this.onExpandClick(e)}
                    className={classes.expandButton}
                >
                    Session infos
                    {!isExpanded && <ChevronDown />}
                    {isExpanded && <ChevronUp />}
                </Button>
                <Collapse in={isExpanded}>
                    <ul className={classes.list}>
                        <li>
                            <Identifier />
                            {sessionId}
                        </li>
                        <li>
                            <CalendarRange />
                            {moment.unix(timestamp).format('H:mm Y/M/D')}
                        </li>
                        <li>
                            <StorefrontOutline />
                            Placement (PID): {pid}
                        </li>
                        <li>
                            {os}
                            {sessionInfo.os} ({sessionInfo.osVersion})
                        </li>
                        <li>
                            <CellphoneArrowDown />
                            SDK Version: {sessionInfo.sdkVersion}
                        </li>
                        <li>
                            <PackageVariant />
                            {sessionInfo.appId} ({sessionInfo.appVersion})
                        </li>
                        {sessionInfo.language && (
                            <li>
                                <Translate />
                                {sessionInfo.language}
                            </li>
                        )}
                        <li>
                            <TabletCellphone />
                            {sessionInfo.deviceFamily}
                        </li>
                        <li>
                            <CellphoneText />
                            {sessionInfo.device}
                        </li>
                        <li>
                            <Signal />
                            {sessionInfo.network}
                        </li>
                        <li>
                            <CellphoneScreenshot />
                            {parseInt(sessionInfo.screenWidth)} x{' '}
                            {parseInt(sessionInfo.screenHeight)}
                        </li>
                        {!!sessionInfo.contextInfo &&
                            sessionInfo.contextInfo.mediation && (
                                <li>
                                    <HandshakeOutline />
                                    {sessionInfo.contextInfo.mediation} (
                                    {sessionInfo.contextInfo.version})
                                </li>
                            )}
                    </ul>
                </Collapse>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    sessionInfo: getValidationSessionInfoSelector(state),
    pid: getPIDSelector(state),
    timestamp: getSessionDateSelector(state),
    sessionId: getValidationSessionIdSelector(state)
})

export default connect(
    mapStateToProps,
    null
)(withStyles(styles, { withTheme: true })(SessionInfo))
