import axios from 'axios'
import {
    ADREQUEST_RECEIVED,
    APPADS_TXT_ERROR,
    APPADS_TXT_RECEIVED,
    CREATIVE_CHECK_RECEIVED,
    GET_SESSION_ERROR,
    GET_SESSION_SUCCESS,
    SDK_VERSION_ERROR,
    SDK_VERSION_RECEIVED,
    TEST_SUBMIT_ERROR,
    TEST_SUBMIT_SUCCESS,
    TRACKING_RECEIVED
} from './validationActionTypes'
import {
    getValidationSessionInfoSelector,
    getValidationSessionSelector
} from './validationSelectors'
import {
    generateVastForTest,
    hydrateSSPUrl,
    hydrateTracking,
    wrapVASTInJSON
} from '../tests/SSPResponseGenerator'
import { ADD_NOTIFICATION } from '../../notification/notificationActionTypes'
import { trackSessionStarted } from '../Utils'
import config from '../../../config'
import {
    initWebsocketClient,
    resetWebsocketClient
} from '../tests/testsReducer'
import { getWebsocketClientSelector } from '../tests/testsSelectors'
import { extractVersionNumber } from '../tests/checks/VersionUtils'

const api = axios.create({
    baseURL: config.apiBaseUrl
})

export const getSession = sessionId => async (dispatch, getState) => {
    try {
        const session = await api.get('/session', {
            params: { testSessionId: sessionId }
        })
        const data = session.data
        if (!data) {
            throw new Error('Session not found')
        }
        await dispatch({
            type: GET_SESSION_SUCCESS,
            payload: {
                ...data,
                id: sessionId
            }
        })
        const sdkVersion = await getSDKVersion(data.sessionInfo.os)
        await dispatch(sdkVersion)
        const appAdsStatus = await getAppAdsStatus(
            data.sessionInfo.appId,
            data.sessionInfo.os,
            data.sessionInfo.language
        )
        await dispatch(appAdsStatus)
        await dispatch({
            type: ADREQUEST_RECEIVED,
            payload: {
                adRequest: data.checks.adrequest
            }
        })
        await dispatch({
            type: TRACKING_RECEIVED,
            payload: {
                trackings: data.checks.tracking
            }
        })
        await dispatch({
            type: CREATIVE_CHECK_RECEIVED,
            payload: {
                creatives: data.checks.creatives
            }
        })
        if (checkForComplete(getState())) {
            dispatch({
                type: TEST_SUBMIT_SUCCESS
            })
        }
    } catch (err) {
        dispatch({
            type: GET_SESSION_ERROR,
            payload: err.toString()
        })
    } finally {
        listenForRealtimeTrackingStop()
    }
}

export const startTest = () => async (dispatch, getState) => {
    const sessionId = getValidationSessionSelector(getState()).id
    const json = wrapVASTInJSON(generateVastForTest(sessionId))

    trackSessionStarted(sessionId, getValidationSessionInfoSelector(getState()))

    const data = {
        response: {
            body: json
        },
        sspUrl: hydrateSSPUrl(sessionId) + '#', // any commander query param are added as url hash
        trackingUrl: hydrateTracking(sessionId)
    }
    try {
        await api.post('/session/start', JSON.stringify(data), {
            params: { testSessionId: sessionId }
        })
        dispatch({
            type: TEST_SUBMIT_SUCCESS
        })
    } catch (err) {
        dispatch({
            type: ADD_NOTIFICATION,
            payload: {
                type: 'error',
                message: 'Failed to submit a test'
            }
        })
        dispatch({
            type: TEST_SUBMIT_ERROR,
            payload: err.toString()
        })
    }
}

export const listenForRealtimeTracking = () => (dispatch, getState) => {
    console.info('Listening start for test')

    const sessionId = getValidationSessionSelector(getState()).id

    const ws = new WebSocket(
        `${config.apiWsBaseUrl}?testSessionId=${sessionId}&comingFrom=frontend`
    )

    ws.onopen = () => {
        dispatch(initWebsocketClient(ws))
    }

    ws.onmessage = event => {
        const data = JSON.parse(event.data)
        switch (data.action) {
            case 'adrequest':
                dispatch({
                    type: ADREQUEST_RECEIVED,
                    payload: {
                        adRequest: data.session.checks.adrequest
                    }
                })
                break
            case 'tracking':
                dispatch({
                    type: TRACKING_RECEIVED,
                    payload: {
                        trackings: data.session.checks.tracking
                    }
                })
                break
            case 'creative-check':
                dispatch({
                    type: CREATIVE_CHECK_RECEIVED,
                    payload: {
                        creatives: data.session.checks.creatives
                    }
                })
                break
            default:
                break
        }
        dispatch(listenForRealtimeTrackingStop())
    }
}

export const listenForRealtimeTrackingStop = () => async (
    dispatch,
    getState
) => {
    if (checkForComplete(getState())) {
        const ws = await getWebsocketClientSelector(getState())

        if (ws) {
            ws.close(1000)

            ws.onclose = () => {
                dispatch(resetWebsocketClient())
            }
        }
    }
}

export const getSDKVersion = os => async dispatch => {
    const urlToFetch = `https://api.github.com/repos/teads/TeadsSDK-${os.toLowerCase()}/releases/latest`

    const params = {
        headers: {}
    }

    if (config.githubToken) {
        params.headers.Authorization = `token ${config.githubToken}`
    }

    try {
        const response = await axios.get(urlToFetch, params)
        dispatch({
            type: SDK_VERSION_RECEIVED,
            payload: {
                latestSdkVersion: extractVersionNumber(response.data.tag_name)
            }
        })
    } catch (err) {
        dispatch({
            type: SDK_VERSION_ERROR,
            payload: {
                error: err,
                latestSdkVersion: ''
            }
        })
    } finally {
        dispatch(listenForRealtimeTrackingStop())
    }
}

export const getAppAdsStatus = (bundle, os, language) => async dispatch => {
    try {
        const response = await api.get('/appads', {
            params: { bundle, os, language }
        })

        const result = {
            status: response.status,
            body: response.data
        }

        const { status, body } = result
        if (status === 200) {
            dispatch({
                type: APPADS_TXT_RECEIVED,
                payload: body
            })
        } else {
            dispatch({
                type: APPADS_TXT_ERROR,
                payload: body
            })
        }
    } catch (err) {
        dispatch({
            type: APPADS_TXT_ERROR,
            payload: err.response ? err.response.data : 'unknown'
        })
    } finally {
        dispatch(listenForRealtimeTrackingStop())
    }
}

const checkForComplete = state =>
    state.validation.data.checks.adRequest !== null &&
    state.validation.data.checks.trackings.filter(tracking =>
        tracking.action.includes('complete')
    ).length > 0 &&
    state.validation.data.appTxtResult !== undefined &&
    state.validation.data.latestSdkVersion !== undefined
